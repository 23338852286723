/* @flow */

import React from 'react';
import { withTranslation } from 'react-i18next';

import AvailableOption from './AvailableOption';
import { OptionTypes } from '../types';

import type { Service, OrderSummary } from '../types';

type Props = {
  summary?: OrderSummary,
  options: Array<Service>,
  addOption: (service: number, previous: boolean) => void,
  removeOption: (id: number) => void,
};

class AvailableOptions extends React.Component {
  static props: Props;

  constructor(props) {
    super(props);
    this.state = { isBasicSelectedLocally: false };
  }

  componentDidUpdate(prevProps: Props) {
    const { summary } = this.props;
    if (prevProps.summary?.hasCancellationOption !== summary?.hasCancellationOption) {
      this.setBasicSelectedLocally(false);
    }
  }

  setBasicSelectedLocally(value: Boolean) {
    this.setState({
      isBasicSelectedLocally: value,
    });
  }

  toggleSelectedOption = (service: number) => {
    const { addOption } = this.props;
    addOption(service);
    this.setBasicSelectedLocally(false);
  };

  removeSelectedOption = () => {
    const { removeOption, summary } = this.props;
    let selectedId = '';
    if (summary.hasCancellationOption && !summary.isCancellationOptionFree) {
      selectedId = summary.cancellationOptionId;
    }
    removeOption(selectedId);
    if (!summary.isCancellationOptionFree) {
      this.setBasicSelectedLocally(true);
    }
  };

  render() {
    const { summary, options, t } = this.props;
    const { isBasicSelectedLocally } = this.state;
    const cancelOption = options.find((s) => s.isInsurance);
    const isCancellationChecked = !isBasicSelectedLocally && summary?.hasCancellationOption;
    const isBasicChecked = !isCancellationChecked && summary?.hasBasicOption;

    return (
      <div className="optionSelection">
        <div className="services">
          {cancelOption && (
            <AvailableOption
              optionType={OptionTypes.premium}
              price={cancelOption.price === 0 ? t('included') : `${cancelOption?.price}€`}
              hasOption={isCancellationChecked}
              addOption={this.toggleSelectedOption}
              removeOption={this.removeSelectedOption}
              cancelServiceId={summary.cancellationOptionId}
              isRecommended
            />
          )}
          {!summary.isCancellationOptionFree && (
            <AvailableOption
              optionType={OptionTypes.basic}
              price={t('free')}
              hasOption={isBasicChecked}
              addOption={this.toggleSelectedOption}
              removeOption={this.removeSelectedOption}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(AvailableOptions);
