/* @flow */

import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import Classnames from 'classnames';

import { ButtonIcon, ModalService, Tooltip } from '@bluevalet/common-ui';

import ChevronIcon from '@bluevalet/react-icons/icon-chevron';
import CloseIcon from '@bluevalet/react-icons/icon-close';
import TickIcon from '@bluevalet/react-icons/icon-tick';

// Service Icons
import InsuranceIcon from '@bluevalet/react-icons/insurance';
import TirePressureIcon from '@bluevalet/react-icons/tire-pressure';
import CoveredParkingIcon from '@bluevalet/react-icons/covered-parking';
import WindscreenWasherIcon from '@bluevalet/react-icons/windscreen-washer';
import ElectricChargingIcon from '@bluevalet/react-icons/electric-charging';
import InsideWashIcon from '@bluevalet/react-icons/inside-wash';
import OutsideWashIcon from '@bluevalet/react-icons/outside-wash';
import IntegralWashIcon from '@bluevalet/react-icons/integral-wash';
import SupplementIcnIcon from '@bluevalet/react-icons/supplement-icn';
import DisinfectionPremiumIcon from '@bluevalet/react-icons/disinfection-premium';
import DisinfectionIcon from '@bluevalet/react-icons/disinfection';
import AutomaticCarWashIcon from '@bluevalet/react-icons/automatic-car-wash';
import OutdoorParkingIcon from '@bluevalet/react-icons/outdoor-parking';

import type { Service } from '../types';

type Props = {
  service: Service,
  selected: boolean,
  enabledByDefault: boolean,
  onToggleService: (id: number) => void,
};

const getIconService = (serviceImage, size, disabled) => {
  if (!serviceImage) return '';

  switch (serviceImage.name) {
    case 'asssurance.jpg':
      return <InsuranceIcon size={size} disabled={disabled} />;
    case 'pression-pneu.jpg':
      return <TirePressureIcon size={size} disabled={disabled} />;
    case 'parking-couvert.jpg':
      return <CoveredParkingIcon size={size} disabled={disabled} />;
    case 'lave-vitre.jpg':
      return <WindscreenWasherIcon size={size} disabled={disabled} />;
    case 'recharge-vehicule.jpg':
      return <ElectricChargingIcon size={size} disabled={disabled} />;
    case 'lavage-interieur.jpg':
      return <InsideWashIcon size={size} disabled={disabled} />;
    case 'lavage-exterieur.jpg':
      return <OutsideWashIcon size={size} disabled={disabled} />;
    case 'lavage-integral.jpg':
      return <IntegralWashIcon size={size} disabled={disabled} />;
    case 'dessablage.jpg':
      return <SupplementIcnIcon size={size} disabled={disabled} />;
    case 'VIREX.jpg':
      return <DisinfectionIcon size={size} disabled={disabled} />;
    case 'OZONETECH.jpg':
      return <DisinfectionPremiumIcon size={size} disabled={disabled} />;
    case 'lavage-rouleau.jpg':
      return <AutomaticCarWashIcon size={size} disabled={disabled} />;
    case 'parking-exterieur.jpg':
      return <OutdoorParkingIcon size={size} disabled={disabled} />;
    default:
      return '';
  }
};

const AvailableService = ({
  t,
  service,
  selected,
  enabledByDefault,
  onToggleService,
}: Props) => {
  const modalRef = useRef(null);
  const toggleService = () => {
    if (!service.isDisabled) {
      onToggleService(service.id, selected);
    }
  };

  const priceLabel = service.price === 0 ? t('included') : `${service.price}€`;
  const disabled = service.isDisabled;
  const clsService = Classnames('service', {
    disabled,
    selected,
  });

  const openServiceModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    modalRef.current.openModal();
  };

  const toggleServiceLabel = selected
    ? t('booking-services_remove_service')
    : t('booking-services_add_service');

  return (
    <div className={clsService} onClick={toggleService} role="button" aria-pressed={selected} tabIndex="0">
      <div className="informations">
        {service.image && getIconService(service.image, 35, disabled)}
        <h4>{service.label}</h4>
        {disabled && <Tooltip label={t('unavailable')} type="blue" />}
        <span
          role="presentation"
          className="see-more"
          onClick={openServiceModal}
        >
          <span>{t('booking-know_more')}</span>
          <ChevronIcon size={16} />
        </span>
      </div>
      <div className="choose">
        <span className="price">{priceLabel}</span>
        {
          !selected
            ? (
              <ButtonIcon disabled={service.isDisabled}>
                <CloseIcon size={18} />
              </ButtonIcon>
            ) : (
              <ButtonIcon>
                <TickIcon size={18} />
              </ButtonIcon>
            )
        }
      </div>

      <ModalService
        ref={modalRef}
        icon={getIconService(service.image, 45)}
        name={service.label}
        price={priceLabel}
        description={service.description}
        isDisabled={disabled}
        buttonProps={!enabledByDefault && {
          label: toggleServiceLabel,
          handleClick: () => {
            modalRef.current.closeModal();
            toggleService();
          },
        }}
      />
    </div>
  );
};

export default withTranslation()(AvailableService);
