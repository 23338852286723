/* @flow */

import React from 'react';
import { withTranslation } from 'react-i18next';

import RemoveIcon from '@bluevalet/react-icons/icon-remove';

import { displayDecimal } from '../utils';
import type { Service } from '../types';

type Props = {
  t: (key: string) => string,
  services: Array<Service>,
  onUnchecked: (service: number) => void,
  enableServiceToggle?: boolean,
};

const SummaryService = ({ t, service, enableServiceToggle, onUnchecked }) => {
  const unchecked = () => onUnchecked(service.id);
  const priceLabel = () => {
    if (service.price === 0) {
      return t('included');
    }
    return `+${displayDecimal(service.price)} €`;
  };

  return (
    <li className="service">
      {enableServiceToggle && <RemoveIcon size={18} onClick={unchecked} />}
      <h3>{service.label}</h3>
      <div className="service-price">{priceLabel()}</div>
    </li>
  );
};

const SummaryServices = ({ t, services, enableServiceToggle, onUnchecked }: Props) => {
  if (services.length > 0) {
    return (
      <ul className="services">
        {services.map((service) => (
          <SummaryService
            t={t}
            key={service.id}
            service={service}
            enableServiceToggle={enableServiceToggle}
            onUnchecked={onUnchecked}
          />
        ))}
      </ul>
    );
  }

  return (
    <ul className="services">
      <li className="service no-services">
        <h3>{t('booking-no_selected_services')}</h3>
      </li>
    </ul>
  );
};

export default withTranslation()(SummaryServices);
