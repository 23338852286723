/* @flow */
import React, { Element, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import * as Immutable from 'immutable';

import { withOrder } from '../withOrder';
import { getServices, isFetchingServices } from '../../reducers/services';
import { getOptions, getSummary, getError } from '../../reducers/cart';
import { toggleOption, persistCart } from '../../actions/cart';
import AvailableServices from '../AvailableServices';
import { NextStepButton } from '../NextStepButton';

import type { Service, OrderSummary } from '../../types';
import type { FormContent } from '../Form';


type Props = {
  loader: Element<any>,
  t: (key: string) => string,
  departure: FormContent,
  arrival: FormContent,
  services: Array<Service>,
  options?: Immutable.Set<number>,
  summary?: OrderSummary,
  error?: string,
  toggleOption: (option: number) => void,
  persistCart: () => void,
};

class ServicesStep extends PureComponent {
  static props: Props;

  componentDidUpdate(prevProps) {
    const { options, persistCart } = this.props;
    if (prevProps.options !== options) {
      persistCart(undefined, true);
    }
  }

  render() {
    const {
      loader,
      t,
      services,
      departure,
      arrival,
      options,
      error,
      summary,
      toggleOption,
      isFetchingServices,
    } = this.props;
    const servicesWithoutOptions = services.filter((service) => !service.isInsurance);

    return isFetchingServices ? loader : (
      <div id="order-services" className="bookingStep">
        {!error && (
          <>
            <h2>{`2. ${t('booking-services')}`}</h2>
            <div className="booking-content">
              {!isFetchingServices && (
                <AvailableServices
                  departure={departure}
                  arrival={arrival}
                  services={servicesWithoutOptions}
                  enabled={options}
                  summary={summary}
                  onToggleService={toggleOption}
                />
              )}
            </div>
            <NextStepButton
              label={t('booking-continue')}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  services: getServices(state),
  options: getOptions(state),
  summary: getSummary(state),
  error: getError(state),
  isFetchingServices: isFetchingServices(state),
});

export default compose(
  withOrder,
  connect(mapStateToProps, {
    toggleOption,
    persistCart,
  }),
  withTranslation(),
)(ServicesStep);
