/* @flow */

import React, { useState } from 'react';
import Classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import * as Immutable from 'immutable';
import ChevronIcon from '@bluevalet/react-icons/icon-chevron';

import AvailableService from './AvailableService';
import { stringNotEmpty } from '../utils';

import type { Service, OrderSummary } from '../types';

const ENABLE_PAGINATION = false;
const NB_DISPLAYED_SERVICES = 6;

type Props = {
  t: (key: string) => string,
  services: Array<Service>,
  summary?: OrderSummary,
  enabled?: Immutable.Set<number>,
  onToggleService: (id: number) => void,
};

const AvailableServices = ({
  t,
  services,
  summary,
  enabled,
  onToggleService,
  departure,
  arrival,
}: Props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const nbPages = Math.ceil(services.length / 6);
  const minIndex = (currentPage - 1) * NB_DISPLAYED_SERVICES;
  const displayedServices = ENABLE_PAGINATION
    ? services.slice(minIndex, minIndex + NB_DISPLAYED_SERVICES)
    : services;

  const goTo = (nbPage) => {
    if (nbPage > 0 && nbPage <= nbPages) {
      setCurrentPage(nbPage);
    }
  };
  const goToPrevious = () => goTo(currentPage - 1);
  const goToNext = () => goTo(currentPage + 1);

  const isServiceChecked = (service: Service): boolean => {
    const enabledLocally = !!enabled && enabled.has(service.id);
    const enabledRemotely = !!summary && summary.services.map((s) => s.id).indexOf(service.id) > -1;

    return enabledLocally || enabledRemotely;
  };

  const clsPreviousPage = Classnames('previous-page', {
    disabled: currentPage === 1,
  });

  const clsNextPage = Classnames('next-page', {
    disabled: currentPage === nbPages,
  });

  const isTripFulfilled = () => stringNotEmpty(departure.site)
    && stringNotEmpty(departure.date)
    && stringNotEmpty(departure.hour)
    && stringNotEmpty(arrival.site)
    && stringNotEmpty(arrival.date)
    && stringNotEmpty(arrival.hour);

  return (
    <div className={`AvailableServices ${ENABLE_PAGINATION ? 'paginated' : ''}`}>
      {services && services.length > 0 ? (
        <>
          <div className="services">
            {displayedServices.map((s) => (
              <AvailableService
                key={s.id}
                service={s}
                selected={isServiceChecked(s)}
                onToggleService={onToggleService}
                enabledByDefault={s.enabledByDefault}
              />
            ))}
          </div>
          {ENABLE_PAGINATION ? (
            <div className="services-pagination">
              <div className={clsPreviousPage}>
                <ChevronIcon size={24} onClick={goToPrevious} />
              </div>
              {Array.from({ length: nbPages }).map((_, i) => (
                <span
                  key={i}
                  role="presentation"
                  className={`page ${i + 1 === currentPage ? 'active' : ''}`}
                  onClick={() => goTo(i + 1)}
                >
                  {i + 1}
                </span>
              ))}
              <div className={clsNextPage}>
                <ChevronIcon size={24} onClick={goToNext} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className="no-services">
          {isTripFulfilled()
            ? t('booking-services_no-services-available')
            : t('booking-services_fill-details-before')}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(AvailableServices);
