import $ from 'jquery';
import { REHYDRATE } from 'redux-persist/lib/constants';
import {
  bindPaymentMethods,
  enablePaymentSubmit,
  enableSubmitIfPossible,
  setPaymentMethod,
} from '../common/payment';
import { pushEvent, appendClientIdToForm } from '../tagging';
import { getLang } from '../../i18n';
import { validatePayment } from '../common/waiting-payment';
import { handleBookingAction, pushPurchaseEvent, pushPurchaseFailureEvent } from './tagging';
import getStore from './store';
import { displayDecimal } from './utils';
import { CART_PERSISTED, fetchSummary } from './actions/cart';
import { fetchSites } from './actions/site';
import { fetchServices } from './actions/service';
import { appendNewsletterToForm } from '../common/newsletter';

const updateTotalCta = () => {
  const total = $('#FinishForm input[name="total"]').val();
  const method = $('input[name="paymentMethod"]').val();
  const availableCredits =
    method === 'card' ? 0 : parseInt($('#CreditsCount').attr('data-available') || '0', 10);
  const totalDue = Math.max(total - availableCredits, 0);

  $('#FinishForm .confirm button > span').text(`${displayDecimal(totalDue, true)} €`);
};

const onCartPersisted = (store) => {
  const { cart } = store.getState();

  if (cart?.summary?.total) {
    const $hiddenTotal = $('#FinishForm input[name="total"]');
    $hiddenTotal.val(cart.summary.total);
    updateTotalCta();
  }
};

const onRehydrate = (store) => {
  store.dispatch(fetchSites());
  store.dispatch(fetchServices());
  store.dispatch(fetchSummary());
};

const paymentMiddleware = (store) => (next) => (action) => {
  next(action);

  // Handling async errors
  switch (action.type) {
    case REHYDRATE:
      onRehydrate(store);
      break;
    case CART_PERSISTED:
      onCartPersisted(store);
      break;
    default:
      break;
  }

  // Perform tagging based on action
  handleBookingAction(store, action);
};

const hasEnoughCredits = ($form, availableCredits) => {
  const totalOrder = parseFloat($form.find('input[name="total"]')?.val() || 0);
  const totalCredits = parseInt(availableCredits, 10);
  return totalCredits >= totalOrder;
};

function bindPaymentOptions($form) {
  const $options = $form.find('.paymentOptions');

  // Handling payment method selector (credits or card)
  const $cb = $options.find('.creditCard');
  const $credits = $options.find('.useCredit');
  const $creditsCount = $('#CreditsCount');
  const availableCredits = $creditsCount.attr('data-available') || '0';
  const $input = $creditsCount.find('input[name="creditsCount"]');

  const $totalContainer = $('#CartPrice');
  const $cbForm = $form.find('.byCard');
  const $creditsTotal = $totalContainer.find('.byCredits');

  const toggleTotal = ($active, $inactive) => {
    $active.removeClass('active');
    $inactive.addClass('active');
  };

  const toggle = ($active, $inactive, useCredits) => {
    if ($inactive.hasClass('active')) return false;

    $active.removeClass('active');
    $inactive.addClass('active');

    if (useCredits) {
      $input.val(availableCredits);
    } else {
      $input.val('0');
    }

    return true;
  };

  $cb.on('click', () => {
    toggle($credits, $cb, false);
    toggleTotal($creditsTotal, $cbForm);
    setPaymentMethod('card');
    updateTotalCta();
    $creditsCount.hide();
    enableSubmitIfPossible($form);
  });

  $credits.on('click', () => {
    if (hasEnoughCredits($form, availableCredits)) {
      toggleTotal($cbForm, $creditsTotal);
      setPaymentMethod('credits');
      enablePaymentSubmit($form);
    } else {
      $creditsTotal.addClass('active');
      setPaymentMethod('card+credits');
      enableSubmitIfPossible($form);
    }
    toggle($cb, $credits, true);
    updateTotalCta();
    $creditsCount.show();
  });
}

export function Payment() {
  const $form = $('#FinishForm');
  bindPaymentOptions($form);
  bindPaymentMethods($form);
  appendClientIdToForm($form);
  appendNewsletterToForm($form);

  getStore([paymentMiddleware]);
}

export function WaitingPayment() {
  const $waiting = $('#WaitingPaymentValidation');
  if ($waiting && $waiting.length) {
    validatePayment();
  }
}

export function PaymentSuccess() {
  const $accepted = $('#PaymentAccepted');
  if ($accepted && $accepted.length) {
    // If payment is accepted, then remove persisted cart
    localStorage.removeItem('persist:root');

    pushEvent('updatevirtualpath', {
      virtualDocumentPath: `/${getLang()}/payment/accepted`,
      virtualDocumentTitle: 'Paiement Accepté',
    });

    // Sending new/old customer event
    try {
      const orderCount = Number($accepted.attr('data-order-count'));
      if (orderCount <= 1) {
        pushEvent('isNew', {
          newCustomer: 'nouveau',
        });
      } else {
        pushEvent('isNew', {
          newCustomer: 'ancien',
        });
      }
    } catch (error) {
      // Ignoring
    }

    // Sending transaction event
    try {
      const code = $accepted.attr('data-order-code');
      const total = $accepted.attr('data-order-total');
      const email = $accepted.attr('data-order-email');
      const userId = $accepted.attr('data-order-user-id');
      const externalId = $accepted.attr('data-order-external-id');

      pushEvent('Transaction', {
        ti: code,
        tr: total,
        mailAcheteur: email,
        userId,
        externalId,
      });

      pushPurchaseEvent(total, code);
    } catch (error) {
      // Ignoring
    }
  }
}

export function PaymentFailure() {
  if ($('#PaymentRefused').length) {
    pushEvent('updatevirtualpath', {
      virtualDocumentPath: `/${getLang()}/payment/refused`,
      virtualDocumentTitle: 'Paiement Refusé',
    });
    pushPurchaseFailureEvent();
  }
}
