/* @flow */

import debounce from 'lodash.debounce';
import API from '../../common/api';
import { serializeBooking } from '../utils';
import type { BusinessWorker, OrderSummary } from '../types';
import type { State } from '../reducers';

export const TOGGLE_OPTION: string = 'TOGGLE_OPTION';
export const ADD_OPTION: string = 'ADD_OPTION';
export const REMOVE_OPTION: string = 'REMOVE_OPTION';
export const CLEAR_OPTIONS: string = 'CLEAR_OPTIONS';
export const UPDATE_VOUCHER: string = 'UPDATE_VOUCHER';
export const UPDATE_BUSINESS: string = 'UPDATE_BUSINESS';
export const UPDATE_TRAVEL_TYPE: string = 'UPDATE_TRAVEL_TYPE';
export const CART_PERSISTED: string = 'CART_PERSISTED';
export const CART_PERSIST_ERROR: string = 'CART_PERSIST_ERROR';
export const FETCH_SUMMARY: string = 'FETCH_SUMMARY';
export const FETCH_SUMMARY_SUCCESS: string = 'FETCH_SUMMARY_SUCCESS';
export const FETCH_SUMMARY_FAILURE: string = 'FETCH_SUMMARY_FAILURE';

const fetchSummarySuccess = (summary?: OrderSummary) => ({
  type: FETCH_SUMMARY_SUCCESS,
  summary,
});

const fetchSummaryFailure = (error: Error) => ({
  type: FETCH_SUMMARY_FAILURE,
  error,
});

export const fetchSummary = () => (dispatch) => {
  dispatch({
    type: FETCH_SUMMARY,
  });
  try {
    API.getSummary()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((summary) => dispatch(fetchSummarySuccess(summary)));
        }
      })
      .catch((fetchError) => {
        fetchError.res.json().then((err) => dispatch(fetchSummaryFailure(err)));
      });
  } catch (e) {
    dispatch(fetchSummaryFailure(e));
  }
};

export const removeOption = (option: number) => ({
  type: REMOVE_OPTION,
  option,
});

export const addOption = (option: number) => ({
  type: ADD_OPTION,
  option,
});

export const toggleOption = (option: number, previous: boolean) => (
  dispatch,
  getState: () => State,
) => {
  // Handling exclusive categories
  const state = getState();

  const { services } = state.services;

  const findServiceById = (id) => services.find((s) => s.id.toString() === id.toString());

  const optionService = findServiceById(option);
  if (optionService) {
    dispatch({
      type: TOGGLE_OPTION,
      option,
      previous,
    });

    // Handling exclusive services
    if (optionService.isExclusiveWith) {
      const toClear = services
        .filter((s) => s.id.toString() !== optionService.id.toString())
        .filter((s) => s.isExclusiveWith === optionService.isExclusiveWith)
        .map((s) => s.id);

      toClear.map((id) => dispatch(removeOption(id)));
    }
  }
};

export const clearOptions = () => ({
  type: CLEAR_OPTIONS,
});

export const updateTravelType = (
  travelType: string,
  businessCustomerId?: string,
  worker?: BusinessWorker,
) => ({
  type: UPDATE_TRAVEL_TYPE,
  travelType,
  business: businessCustomerId,
  worker,
});

export const cartPersisted = (summary?: OrderSummary) => ({
  type: CART_PERSISTED,
  summary,
});

export const cartPersistError = (error?: any) => ({
  type: CART_PERSIST_ERROR,
  error,
});

export const persistCart = (
  callbacksToDispatch?: Array<() => void>,
  servicesSelect?: Boolean,
  optionsSelect?: Boolean,
) => (
  dispatch,
  getState: () => State,
) => {
  const state = getState();

  const { departure, arrival } = state.booking;
  const { options, voucher, business, worker, travelType } = state.cart;

  const { start, end } = serializeBooking(departure, arrival);

  const body = JSON.stringify({
    ...start,
    ...end,
    services: options ? options.toArray() : undefined,
    voucher,
    business,
    travelType,
    isSubstituted: worker && worker.isWorker,
    servicesSelect,
    optionsSelect,
  });

  API.saveCart(body)
    .then((res) => {
      if (res.status === 200) {
        res.json().then((summary) => {
          if (callbacksToDispatch && callbacksToDispatch.length) {
            callbacksToDispatch.map((fn) => dispatch(fn()));
          }
          return dispatch(cartPersisted(summary));
        });
      } else {
        dispatch(cartPersisted());
      }
    })
    .catch((fetchError) => {
      try {
        fetchError.res.json().then((err) => dispatch(cartPersistError(err)));
      } catch (err) {
        dispatch(cartPersistError(err));
      }
    });
};

const debouncePersist = debounce((dispatch) => dispatch(persistCart()), 600);

export const updateVoucher = (voucher: string) => (dispatch) => {
  dispatch({
    type: UPDATE_VOUCHER,
    voucher,
  });

  // Debouncing API call
  debouncePersist(dispatch);
};
