/* @flow */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import * as Immutable from 'immutable';
import Classnames from 'classnames';
import moment from 'moment';
import IconChevron from '@bluevalet/react-icons/icon-chevron';

import useMediaQuery from '../../../plugins/useMediaQuery';
import { displayDecimal, getSiteWithId } from '../utils';
import { persistCart, toggleOption, updateVoucher } from '../actions/cart';
import { getServices } from '../reducers/services';
import { getBusiness, getIsUpdatingVoucher, getOptions, getSummary, getVoucher } from '../reducers/cart';
import SummaryDiscount from './SummaryDiscount';
import SummaryPrice from './SummaryPrice';
import SummaryServices from './SummaryServices';
import Voucher from './Voucher';

import type { FormContent } from './Form';
import type { OrderSummary, Service, Site } from '../types';

type Props = {
  t: (key: string) => string,
  sites: Array<Site>,
  services: Array<Service>,
  departure: FormContent,
  arrival: FormContent,
  options?: Immutable.Set<number>,
  summary?: OrderSummary,
  business?: string,
  enableVoucher?: boolean,
  enableServiceToggle?: boolean,
  toggleOption: (service: number) => void,
  updateVoucher: (voucher: string) => void,
  persistCart: () => void,
  isSticky: boolean
};

const mapSummaryServices = (summary: OrderSummary, services: Array<Service>) => (
  summary.services
    .map((os) => services.find((s) => s.id === os.id))
    .filter((s) => s !== undefined)
);

const getTotalParts = (total?: number) => {
  let base = '--';
  let fixed = '--';
  if (total) {
    [base, fixed] = displayDecimal(total).split('.');
  }
  return [base, fixed];
};

const getFormattedPrice = (price: number) => {
  const [base, fixed] = getTotalParts(price);
  return `${base},${fixed} €`;
};

const displayPriceOrFree = (price: number, freeLabel: string) => {
  if (price === 0) {
    return <span className="Price Free">{freeLabel}</span>;
  }
  return (<span className="Price">{getFormattedPrice(price)}</span>);
};

const displayTotal = (total?: number) => {
  const [base, fixed] = getTotalParts(total);
  return (<span className="amount"><em>{base}</em>{','}<small>{fixed}</small>{' €'}</span>);
};

const displayDate = (date?: Date) => (date ? moment(date).format('ddd D MMMM') : '');

function FullBookingSummary({
  t,
  i18n,
  sites,
  services,
  departure,
  arrival,
  options,
  summary,
  business,
  enableVoucher,
  voucher,
  updateVoucher,
  isUpdatingVoucher,
  enableServiceToggle,
  toggleOption,
  persistCart,
  isSticky,
}: Props) {
  const [isExpended, setIsExpended] = useState(false);
  const isMobile = useMediaQuery('(max-width: 960px)');

  const orderServices = summary
    ? mapSummaryServices(summary, services)
    : services.filter((s) => options && options.has(s.id));

  const totalLabel = summary && orderServices && orderServices.length > 0
    ? t('booking-total_notice_with_services')
    : t('booking-total_notice');

  const onUnchecked = (service) => {
    toggleOption(service);
    persistCart();
  };

  const renderMobile = () => {
    const departureSite = getSiteWithId(sites, departure.site);
    const arrivalSite = getSiteWithId(sites, arrival.site);

    const hasVoucher = summary?.voucher?.status === 'Valid';
    const hasDiscount = summary?.baseWithoutDiscount &&
      summary?.baseWithoutDiscount > summary?.base;

    const savings = (
      (hasDiscount ? (summary?.baseWithoutDiscount - summary?.base) : 0) +
        (hasVoucher ? summary.voucher.discount : 0)
    );

    const clsContainer = Classnames('MobileSummaryContainer', { sticky: isSticky });
    const clsButton = Classnames('ShowButton', { expanded: isExpended });
    const clsSummary = Classnames('MobileSummary', { expanded: isExpended });

    return (
      <div className={clsContainer}>
        <div className={clsSummary}>
          <div className="Sites">
            {departureSite?.name}
            {departureSite && arrivalSite && departureSite.id !== arrivalSite.id && (
              <span> ⟶ {arrivalSite?.name}</span>
            )}
          </div>
          <div className="Trip">
            <div className="TravelBar" />
            <div className="Departure">
              <span>{t('booking-departure')}</span>
              <span>{displayDate(departure.date)} | {departure.hour}</span>
            </div>
            <div className="Return">
              <span>{t('booking-arrival')}</span>
              <span>{displayDate(arrival.date)} | {arrival.hour}</span>
            </div>
          </div>

          {(isExpended || !isSticky) && (
            <>
              {enableVoucher && !business && (
                <>
                  <div className="Separator" />
                  <Voucher
                    value={voucher}
                    status={summary && summary.voucher}
                    updateVoucher={updateVoucher}
                    isUpdatingVoucher={isUpdatingVoucher}
                  />
                  <div className="Separator" />
                </>
              )}
              <div className="LinesTitle">{t('booking-summary_title')}</div>
              <div className="Lines">
                <div className="Line Bullet">
                  <span>Parking + Voiturier</span>
                  <span className="FinalPrice">
                    {hasDiscount && (
                      <span className="CrossedOutPriceWrapper">
                          {'('}<span className="CrossedOutPrice">{getFormattedPrice(summary?.baseWithoutDiscount)}</span>{')'}
                      </span>
                    )}
                    <span className="Price">{getFormattedPrice(summary?.base)}</span>
                  </span>
                </div>
                {!!summary?.switch && (
                  <div className="Line Bullet">
                    <span>{t('booking-switch_price')}</span>
                    {displayPriceOrFree(summary.switch, t('free'))}
                  </div>
                )}
                {summary?.services?.map((service, i) => (
                  <div key={i} className="Line Bullet">
                    <span>{service.name[i18n.language]}</span>
                    {displayPriceOrFree(service.price, t('free'))}
                  </div>
                ))}
                {hasVoucher && (
                  <div className="Line Bullet Voucher">
                    <span>{t('booking-voucher')} <em>({summary.voucher.code})</em></span>
                    <span className="Price">{getFormattedPrice(-summary.voucher.discount)}</span>
                  </div>
                )}
                <div className="Line Total">
                  <span>Total</span>
                  <span className="PriceWrapper">
                    <span className="Price">{getFormattedPrice(summary?.total)}</span>
                    {!!savings && (
                      <span className="Savings">
                        <Trans
                          i18nKey="booking-savings"
                          values={{ amount: getFormattedPrice(savings) }}
                        />
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </>
          )}

          {isSticky && (
            <div className={clsButton}>
              <IconChevron size={28} onClick={() => setIsExpended(!isExpended)} role="button" />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderDesktop = () => (
    <div className="FullBookingSummary">
      <h2>{t('booking-summary_title')}</h2>
      <div className="summary show">
        <div className="content">
          <h2>{t('booking-your_trip')}</h2>
          <SummaryPrice
            sites={sites}
            departure={departure}
            arrival={arrival}
            summary={summary}
          />
          <h2>{t('booking-your_services')}</h2>
          <SummaryServices
            services={orderServices}
            enableServiceToggle={enableServiceToggle}
            onUnchecked={onUnchecked}
          />
          {enableVoucher && !business && (
            <Voucher
              value={voucher}
              status={summary && summary.voucher}
              updateVoucher={updateVoucher}
              isUpdatingVoucher={isUpdatingVoucher}
            />
          )}
          {summary && !!summary.valetDiscount && (
            <SummaryDiscount
              discount={summary.valetDiscount}
              isDiscountSitePromotion={summary.isDiscountSitePromotion}
            />
          )}
        </div>
        <div className="content final-price">
          <h4>
            {t('booking-total_amount')}
            <span>{totalLabel}</span>
          </h4>
          {displayTotal(summary?.total)}
        </div>
      </div>
    </div>
  );

  return isMobile ? renderMobile() : renderDesktop();
}

const mapStateToProps = (state) => ({
  ...state.sites,
  ...state.booking,
  services: getServices(state),
  options: getOptions(state),
  business: getBusiness(state),
  summary: getSummary(state),
  voucher: getVoucher(state),
  isUpdatingVoucher: getIsUpdatingVoucher(state),
});

export default compose(
  connect(
    mapStateToProps,
    {
      toggleOption,
      updateVoucher,
      persistCart,
    },
  ),
  withTranslation(),
)(FullBookingSummary);
